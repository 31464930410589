"use client";

import { useEffect, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";

import { Container } from "./Container";

const features = [
  {
    title: "Drag and Drop Homepage Builder",
    description: "Drag and drop over 15 components to build your bot's landing page. Customize every aspect of your bot's website to your liking.",
    image: process.env.PUBLIC_URL + "/img/homepage.png",
  },
  {
    title: "Configurable Bot Dashboard",
    description: "Create a configurable dashboard for your Discord bot without coding. Allow users to customize your bot for their server",
    image: process.env.PUBLIC_URL + "/img/dashboard.png",
  },

  {
    title: "Collect Payments",
    description: "Setup subscription's and collect payments from your users using Stripe with 0% platform fees. ",
    image: process.env.PUBLIC_URL + "/img/monetize.png",
  },
  {
    title: "Detailed Analytics",
    description: "View detailed analytics about your bot's activity and user engagement. See exactly how your bot is performing and how users are interacting with it.",
    image: process.env.PUBLIC_URL + "/img/analytics.png",
  },
];

export function PrimaryFeatures() {
  let [tabOrientation, setTabOrientation] = useState("horizontal");
  let [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    let lgMediaQuery = window.matchMedia("(min-width: 1024px)");

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? "vertical" : "horizontal");
    }

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addEventListener("change", onMediaQueryChange);

    return () => {
      lgMediaQuery.removeEventListener("change", onMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 10000); // Change feature every 5 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section id="features" aria-label="Features for running your books" className="bg-base-blue relative overflow-hidden pb-28 pt-20 sm:py-32">
      <img className="absolute left-1/2 top-1/2 max-w-none translate-x-[-44%] translate-y-[-42%]" src={""} alt="" width={2245} height={1636} unoptimized />
      <Container className="relative">
        <div className="max-w-none md:mx-auto md:text-center xl:max-w-5xl">
          <h2 className="font-display text-3xl font-bold tracking-tight text-white sm:text-4xl md:text-5xl">Everything you need to create your Discord bot's online presence</h2>
          <p className="mt-6 text-lg tracking-tight font-semibold text-white">Make your own configurable dashboard for your Discord bot without coding.</p>
        </div>
        <TabGroup as="div" className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0" vertical={tabOrientation === "vertical"} selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          {({ selectedIndex }) => (
            <>
              <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <TabList className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div key={feature.title} className={clsx("group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-6", selectedIndex === featureIndex ? "bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10" : "hover:bg-white/10 lg:hover:bg-white/5")}>
                      <h3>
                        <Tab className={clsx("font-display text-lg [&:not(:focus-visible)]:focus:outline-none", selectedIndex === featureIndex ? "text-blue-600 lg:text-white" : "text-blue-100 hover:text-white lg:text-white")}>
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {feature.title}
                        </Tab>
                      </h3>
                      <p className={clsx("mt-2 hidden text-sm lg:block", selectedIndex === featureIndex ? "text-white" : "text-blue-100 group-hover:text-white")}>{feature.description}</p>
                    </div>
                  ))}
                </TabList>
              </div>
              <TabPanels className="lg:col-span-7">
                {features.map((feature) => (
                  <TabPanel key={feature.title} unmount={false}>
                    <div className="relative sm:px-6 lg:hidden">
                      <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">{feature.description}</p>
                    </div>
                    <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                      <img className="w-full max-h-[50rem]" src={feature.image} alt="" sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem" />
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            </>
          )}
        </TabGroup>
      </Container>
    </section>
  );
}
