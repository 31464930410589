import { Container } from "./Container";
import { NavLink } from "./NavLink";

export function Footer() {
  return (
    <footer className="">
      <Container>
        <div className="py-16">
          <div className="w-full flex items-center justify-center">
            <a href="#" className="font-semibold text-xl text-white flex flex-row items-center gap-x-3" aria-label="Home">
              <img className="h-9" src={process.env.PUBLIC_URL + "/img/logo.png"} alt="BotPanel Logo" />
              <span>BotPanel</span>
            </a>
          </div>
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 grid grid-cols-2 lg:grid-cols-4 text-center gap-x-6">
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
              <NavLink href="/privacy-policy">Privacy Policy</NavLink>
              <NavLink href="/terms-of-service">Terms of Service</NavLink>
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <p className="mt-6 text-sm text-slate-400 sm:mt-0">Copyright &copy; {new Date().getFullYear()} BotGhost Pty Ltd. All rights reserved.</p>
        </div>
      </Container>
    </footer>
  );
}
