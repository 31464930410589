import { Button } from "./Button";
import { Container } from "./Container";
// import backgroundImage from "@/images/background-call-to-action.jpg";

export function CallToAction() {
  return (
    <section id="get-started-today" className="relative overflow-hidden bg-base-blue py-32">
      <img className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2" src={""} alt="" width={2347} height={1244} unoptimized />
      <Container className="relative">
        <div className="mx-auto text-center">
          <h2 className="font-bold text-3xl f tracking-tight text-white sm:text-4xl">Get started for free today</h2>
          <p className="mt-4 text-lg tracking-tight text-white">Create a free Discord Bot Dashboard and homepage for your users. </p>
          <Button href="/register" color="white" className="mt-10">
            Get Started
          </Button>
        </div>
      </Container>
    </section>
  );
}
