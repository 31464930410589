import React, { Component } from "react";
import { Button } from "../components/Button";

import { CallToAction } from "../components/CallToAction";
import { Faqs } from "../components/Faqs";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Hero } from "../components/Hero";
import { Pricing } from "../components/Pricing";
import { PrimaryFeatures } from "../components/PrimaryFeatures";
import { SecondaryFeatures } from "../components/SecondaryFeatures";
// import { Testimonials } from "../components/Testimonials";

export class Home extends Component {
  render() {
    return (
      <div className="bg-[#222527]">
        <Header />
        <main>
          <Hero />
          <PrimaryFeatures />
          <SecondaryFeatures />
          <CallToAction />
          <Pricing />
          <Faqs />
        </main>
        <Footer />
        {/* <main>
          <Hero />
          <PrimaryFeatures />
          <SecondaryFeatures />
          <CallToAction />
          <Testimonials />
          <Pricing />
          <Faqs />
        </main>
        <Footer /> */}
      </div>
    );
  }
}

export default Home;
