import React from 'react';
import { Container } from '../components/Container';

export function TermsOfService() {
    return (
        <main className='bg-[#222527] min-h-screen'>
            <Container className="py-16 text-white">
                <h1 className="text-4xl font-bold text-white mb-8">Terms of Service</h1>
                <div className="prose prose-invert max-w-none">
                    <p className="mb-6">Welcome to BotPanel. By using our services, you agree to these terms. Please read them carefully.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">1. Acceptance of Terms</h2>
                    <p className="mb-4">By accessing or using BotPanel's services, you agree to be bound by these Terms of Service and all applicable laws and regulations. If you do not agree with any part of these terms, you may not use our services.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">2. Description of Service</h2>
                    <p className="mb-4">BotPanel provides a platform for creating and managing Discord bot dashboards. Our services are subject to change without notice.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">3. User Accounts</h2>
                    <p className="mb-4">You may need to create an account to use some of our services. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">4. User Conduct</h2>
                    <p className="mb-4">You agree not to use the service for any unlawful purpose or in any way that interrupts, damages, or impairs the service. You may not use the service in a manner that could interfere with other users' use and enjoyment of the service.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">5. Intellectual Property</h2>
                    <p className="mb-4">The content, organization, graphics, design, and other matters related to BotPanel are protected under applicable copyrights and other proprietary laws. Copying, redistribution, use, or publication of any such matters or any part of the service is prohibited without our express permission.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">6. Disclaimer of Warranties</h2>
                    <p className="mb-4">The service is provided "as is" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">7. Limitation of Liability</h2>
                    <p className="mb-4">BotPanel shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages resulting from your use or inability to use the service.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">8. Changes to Terms</h2>
                    <p className="mb-4">We reserve the right to modify these terms at any time. We will notify users of any changes by posting the new Terms of Service on this page.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">9. Governing Law</h2>
                    <p className="mb-4">These terms shall be governed by and construed in accordance with the laws of Australia, without regard to its conflict of law provisions.</p>

                    <h2 className="text-2xl font-bold mt-8 mb-4">10. Contact Information</h2>
                    <p className="mb-4">If you have any questions about these Terms of Service, please contact us at:</p>
                    <p className="font-semibold">support@botghost.com</p>
                </div>
            </Container>
        </main>
    );
}